import React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import { ValidationError } from 'yup'
import Swal from 'sweetalert2'
import get from 'lodash/get'

import UserApi from 'api/auth'

import AuthActions from 'store/auth/actions'
import MessengerActions from 'store/messenger/actions'
import { AppState } from 'store/rootReducer'
import { InputEvent } from 'types/helps'
import { IUser, ISignature } from 'types/models'
import { INotValidFieldsErr } from 'types/errors'

import EditFrame from 'components/EditFrame'
import * as forms from 'components/Forms/ProfileFormStepsEdit'
import Four from 'components/Forms/ProfileFormStepsEdit/4'
import Five from 'components/Forms/ProfileFormStepsEdit/5'

import { updateProfileInfo, updatePaymentDetails, updatePassword, formatErrors } from 'services/validation'

const PROFILE_STEPS_EDIT = ['Profile information', 'Payment details', 'Password settings', 'Profile photo', 'Messaging signature']
const SETTING_TEXTS = {
  title: 'profile',
  deleteBtnText: 'Delete Profile',
}

type Props = {
  user: IUser
  serverError: INotValidFieldsErr
  updateUser: (user: Partial<IUser>, type?: string) => void
  refreshLogin: () => void
  clearNotification: () => void
  isUpdateData: boolean
  logout: typeof AuthActions.logout
  getSignature: typeof MessengerActions.getSignature
  signature: ISignature
  signatureIsFetching: boolean
  signatureTextarea: string
  error: string
}

type State = {
  currentStep: number | any
  user: Partial<IUser>
  errors: Partial<IUser>
}

class EditEmployer extends React.Component<Props & RouteComponentProps, State> {
  state: State = {
    currentStep: (this.props.history.location.state && this.props.history.location.state.currentState) || 0,
    user: {},
    errors: {},
  }
  newPhoto: any = undefined

  componentDidMount() {
    const currentStep = Number(window.location.search.split('=')[1]) || get(this.props, 'history.location.state.step') || 0
    this.setState({ user: this.props.user, currentStep })
    this.props.clearNotification()
    if (this.props.history.location.state) {
      this.setState({ currentStep: this.props.history.location.state.currentState || this.props.history.location.state.step })
    }
    const { getSignature } = this.props
    getSignature()
  }

  componentWillReceiveProps(nextProps: Props) {
    let serverErrors
    if (nextProps.serverError && nextProps.serverError.notValidFields) {
      serverErrors = nextProps.serverError.notValidFields.reduce((obj: any, item) => {
        obj[item.fieldName] = item.errorMessage
        return obj
      }, {})
    }
    this.setState({ errors: { ...this.state.errors, ...serverErrors } })
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.user !== this.props.user) {
      this.state.user = this.props.user
      this.setState({ errors: {} })
    }
  }

  getComponent = () => {
    const { user, currentStep } = this.state
    const { signature, signatureIsFetching, signatureTextarea, error } = this.props

    switch (currentStep) {
      case 3:
        return <Four title={PROFILE_STEPS_EDIT[currentStep]} form={user} onUpdate={this.props.refreshLogin} />
      case 4:
        return (
          <Five
            title={PROFILE_STEPS_EDIT[currentStep]}
            form={user}
            signature={signature}
            signatureIsFetching={signatureIsFetching}
            signatureTextarea={signatureTextarea}
            error={error}
          />
        )
    }
    return undefined
  }

  setStep = (currentStep: number) => {
    this.setState({ currentStep, errors: {} })
    this.props.clearNotification()
  }

  onUpdate = () => {
    const { user } = this.state
    const { user: storeUser } = this.props
    const validatorSteps: any = {
      0: updateProfileInfo,
      1: updatePaymentDetails,
      2: updatePassword,
    }
    const validator = validatorSteps[this.state.currentStep]
    if (user.email !== storeUser.email) {
      if (!user.pass) return this.setState({ errors: { pass: 'Enter password' } })
    }
    validator
      .validate(this.state.user, { abortEarly: false })
      .then(() => {
        this.props.updateUser(this.state.user, 'edit profile')
        this.setState({ errors: {} })
        setTimeout(() => {
          this.props.clearNotification()
        }, 3000)
      })
      .catch((err: ValidationError) => {
        this.setState({ ...this.state, errors: formatErrors(err) })
      })
  }

  onDelete = () => {
    const { logout, history } = this.props
    UserApi.deleteUser().then(() => history.push('/'))
    Swal.fire('User deleted!', undefined, 'success')
    localStorage.removeItem('persist:root')
    logout()
  }

  onChangeField = (e: InputEvent) => {
    const { name, value } = e.currentTarget
    this.setState({ user: { ...this.state.user, [name]: value }, errors: {} })
  }

  setNewPhoto = (newPhoto: any) => {
    return (this.newPhoto = newPhoto)
  }

  render() {
    const { isUpdateData } = this.props
    const { user, currentStep, errors } = this.state
    const customComponent = this.getComponent()
    if (!user) return null
    return (
      <EditFrame
        texts={SETTING_TEXTS}
        steps={PROFILE_STEPS_EDIT}
        forms={forms}
        currentStep={currentStep}
        setStep={this.setStep}
        errors={errors}
        editItemData={user}
        onUpdate={this.onUpdate}
        onDelete={this.onDelete}
        onChangeField={this.onChangeField}
        setNewPhoto={this.setNewPhoto}
        isShowSaveButton={currentStep < 3}
        customComponent={customComponent}
        isUpdateData={isUpdateData}
      />
    )
  }
}

const mapStateToProps = (state: AppState) => ({
  user: state.auth.get('user'),
  serverError: state.auth.get('error'),
  isUpdateData: state.auth.get('isUpdateData'),
  signature: state.messenger.get('signature'),
  signatureIsFetching: state.messenger.get('signatureIsFetching'),
  signatureTextarea: state.messenger.get('signatureTextarea'),
  error: state.messenger.get('error'),
})

export default connect(
  mapStateToProps,
  {
    updateUser: AuthActions.updateUser,
    refreshLogin: AuthActions.refreshLogin,
    clearNotification: AuthActions.clearNotification,
    logout: AuthActions.logout,
    getSignature: MessengerActions.getSignature,
  }
)(EditEmployer)
