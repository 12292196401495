import { call, put, takeLatest } from 'redux-saga/effects'

import ListActions, {
  GET_STATES,
  GET_CITIES,
  GET_JOB_POSITIONS,
  GET_PROJECT_TYPES,
  GET_IT_PACKAGES,
  GET_PROJECT_VALUE,
  GET_PUBLIC_EMPLOYERS,
  GET_PUBLIC_EMPLOYERS_WITHOUT_ID,
} from './actions'

import ListApi from 'api/list'

function* getStatesFlow() {
  const { data } = yield call(ListApi.getStates)
  yield put(ListActions.getStatesSuccess(data))
}

function* getCitiesFlow() {
  const { data } = yield call(ListApi.getCities)
  yield put(ListActions.getCitiesSuccess(data))
}

function* getJobPositionsFlow() {
  const { data } = yield call(ListApi.getJobPositions)
  yield put(ListActions.getJobPositionsSuccess(data))
}

function* getProjectTypesFlow() {
  const { data } = yield call(ListApi.getProjectTypes)
  yield put(ListActions.getProjectTypesSuccess(data))
}

function* getItPackagesFlow() {
  const { data } = yield call(ListApi.getItPackages)
  yield put(ListActions.getItPackagesSuccess(data))
}

function* getProjectValuesFlow() {
  const { data } = yield call(ListApi.getProjectValues)
  yield put(ListActions.getProjectValuesSuccess(data))
}

function* getPublicEmployersFlow(action: ReturnType<typeof ListActions.getPublicEmployers>) {
  const { data } = yield call(ListApi.getPublicEmployers, action.payload)
  yield put(ListActions.getPublicEmployersSuccess(data))
}

function* getPublicEmployersWithoutIdFlow() {
  const { data } = yield call(ListApi.getPublicEmployersWithoutId)
  yield put(ListActions.getPublicEmployersWithoutIdSuccess(data))
}

export function* getAllStatesData() {
  yield takeLatest(GET_STATES, getStatesFlow)
  yield takeLatest(GET_CITIES, getCitiesFlow)
  yield takeLatest(GET_JOB_POSITIONS, getJobPositionsFlow)
  yield takeLatest(GET_PROJECT_TYPES, getProjectTypesFlow)
  yield takeLatest(GET_IT_PACKAGES, getItPackagesFlow)
  yield takeLatest(GET_PROJECT_VALUE, getProjectValuesFlow)
  yield takeLatest(GET_PUBLIC_EMPLOYERS, getPublicEmployersFlow)
  yield takeLatest(GET_PUBLIC_EMPLOYERS_WITHOUT_ID, getPublicEmployersWithoutIdFlow)
}
