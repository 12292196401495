import React from 'react'
import { ISelectListItem } from 'types/forms'
import { IJobPosition } from 'types/models'
import { InputEvent } from 'types/helps'

import Input from 'components/Input'
import InputSelect from 'components/Input/InputSelect'
import CityStateSelect from 'components/Input/cityStateSelect'

import { BoxFill, Image } from 'styles'

const ICON_DOLLAR = <Image src={require('assets/icons/$.svg')} />

type Props = {
  form: Partial<IJobPosition>
  onChangeField: (e: InputEvent & ISelectListItem, type?: string) => void
  errors: Partial<IJobPosition>
  recruiterId: string
}

export const FirstStep: React.FC<Props> = ({ onChangeField, errors, form, recruiterId }) => {
  const onChangeCompanies = (e: InputEvent & ISelectListItem) => onChangeField(e, 'Companies')
  const onChangePosition = (e: InputEvent & ISelectListItem) => onChangeField(e, 'Position')
  const onChangeProjectType = (e: InputEvent & ISelectListItem) => onChangeField(e, 'Sectors')
  const onChangeCity = (e: InputEvent & ISelectListItem) => onChangeField(e, 'City')
  const onChangeItPackages = (e: InputEvent & ISelectListItem) => onChangeField(e, 'ItPackages')
  const onChangeProjectValues = (e: InputEvent & ISelectListItem) => onChangeField(e, 'ProjectValues')

  return (
    <BoxFill column>
      <BoxFill mb="20px" column>
        <InputSelect
          value={form.Companies}
          error={errors.Companies}
          onChange={onChangeCompanies}
          placeholder="Choose a company"
          label="Company"
          optionsType="publicEmployers"
          recruiterId={recruiterId}
        />
      </BoxFill>
      <BoxFill mb="20px">
        <InputSelect
          value={form.Position}
          error={errors.Position}
          onChange={onChangePosition}
          placeholder="Choose job position title"
          label="Job position title"
          optionsType="jobs"
        />
      </BoxFill>
      <BoxFill mb="20px">
        <InputSelect
          value={form.Sectors}
          error={errors.Sectors}
          onChange={onChangeProjectType}
          placeholder="Choose project type"
          label="Project type (optional)"
          optionsType="projectTypes"
          isMulti
        />
      </BoxFill>
      <BoxFill mb="20px">
        <InputSelect
          value={form.ItPackages}
          isMulti
          onChange={onChangeItPackages}
          placeholder="Choose"
          label="Qualifications / IT Packages (optional)"
          optionsType="itPackages"
        />
      </BoxFill>
      <BoxFill mb="20px">
        <InputSelect
          value={form.ProjectValues}
          isMulti
          onChange={onChangeProjectValues}
          placeholder="Choose"
          label="Project values (optional)"
          optionsType="projectValues"
        />
      </BoxFill>
      <BoxFill mb="20px">
        <CityStateSelect error={errors.City} value={form.City!} onChange={onChangeCity} />
      </BoxFill>
      <BoxFill mb="20px">
        <Input
          value={form.salary}
          error={errors.salary}
          onChange={onChangeField}
          leftElement={ICON_DOLLAR}
          pl="30px"
          name="salary"
          label="Approx. annual salary"
          type="text"
          pattern="[0-9\s]{0,9}"
        />
      </BoxFill>
    </BoxFill>
  )
}
