import React, { useState } from 'react'
import { connect } from 'react-redux'
import { withRouter, RouteComponentProps } from 'react-router-dom'

import NotesActions from 'store/notes/actions'
import { AppState } from 'store/rootReducer'
import EmployersActions from 'store/employers/actions'

import JobPositionInner from 'containers/LiveJobs/JobPositionInner'
import Modal from 'components/Modal'
import Loader from 'components/Loader'
import JobPositionCard from 'components/ForCandidates&Employers/jobPositionCard'
import Button from 'components/Button'

import { smoothScroll } from 'utils'

import { BoxFill } from 'styles'
import { Wrap, ScrollToTop, WrapAddJob } from './styles'

type Props = {
  allJobPositions: any
  onToggleModal: typeof NotesActions.onToggleModal
  jobs: any
  isFetching: boolean
  fetchEmployer: typeof EmployersActions.fetchEmployer
  getAllLiveJobs: typeof EmployersActions.getAllLiveJobs
}

const Content: React.FC<Props & RouteComponentProps> = ({
  allJobPositions,
  onToggleModal,
  history,
  jobs,
  isFetching,
  fetchEmployer,
  getAllLiveJobs,
}) => {
  const [currentPositionInnerId, setCurrentPositionInnerId] = useState<boolean | number | string>(false)
  const [currentEmployerId, setCurrentEmployerId] = useState<any>(false)
  const [showScrollToTop, setScrollToTop] = useState(false)

  const setJobPositionInnerId = (id: number) => setCurrentPositionInnerId(id)
  const setEmployerId = (id: number) => setCurrentEmployerId(id)

  const addCandidateRoute = () => history.push('/candidates/add')
  const editJobPositionRoute = () => history.push(`/live-jobs/employer/${currentEmployerId}/${currentPositionInnerId}/edit`)

  const hideJobPositionInnerModal = () => {
    if (currentEmployerId && jobs) {
      fetchEmployer(currentEmployerId)
    } else {
      getAllLiveJobs()
    }
    setCurrentPositionInnerId(false)
  }

  window.addEventListener('scroll', () => {
    if (document.body.scrollTop > 150 || document.documentElement.scrollTop > 150) {
      setScrollToTop(true)
    } else {
      setScrollToTop(false)
    }
  })

  const sortByPosotionName = (a: any, b: any) => {
    const nameA = a.Position.name.toUpperCase()
    const nameB = b.Position.name.toUpperCase()

    let comparison = 0
    if (nameA > nameB) {
      comparison = 1
    } else if (nameA < nameB) {
      comparison = -1
    }
    return comparison
  }

  return (
    <Wrap column>
      {isFetching ? (
        <Loader withoutBg height="500px" />
      ) : jobs && jobs.length ? (
        <WrapAddJob>
          <BoxFill justifyRight>
            <Button
              width="160px"
              height="60px"
              title="ADD JOB POSITION"
              onClick={() => history.push(`live-jobs/employer/${jobs[0].EmployerId}/add-job`)}
            />
          </BoxFill>
          {jobs.sort(sortByPosotionName).map((el: any) => (
            <JobPositionCard
              key={el.id}
              jobPosition={el}
              setCurrentJobPositionId={setJobPositionInnerId}
              toggleNoteModal={onToggleModal}
              setEmployerId={setEmployerId}
              numberOfCandidates={+el.Candidates.length}
            />
          ))}
        </WrapAddJob>
      ) : (
        allJobPositions &&
        allJobPositions
          .sort(sortByPosotionName)
          .map((el: any) => (
            <JobPositionCard
              key={el.id}
              jobPosition={el}
              setCurrentJobPositionId={setJobPositionInnerId}
              toggleNoteModal={onToggleModal}
              setEmployerId={setEmployerId}
              numberOfCandidates={+el.Candidates.length}
            />
          ))
      )}

      <Modal isOpen={currentPositionInnerId} onRequestClose={hideJobPositionInnerModal} withScroll hideCloseIcon>
        <JobPositionInner
          id={currentPositionInnerId}
          addCandidateRoute={addCandidateRoute}
          editJobPositionRoute={editJobPositionRoute}
          onClose={hideJobPositionInnerModal}
        />
      </Modal>
      {showScrollToTop && <ScrollToTop src={require('assets/icons/scroll-to-top.svg')} onClick={() => smoothScroll()} />}
    </Wrap>
  )
}
const mapStateToProps = (state: AppState) => ({
  isFetching: state.currentInnerPage.get('isFetching'),
})

export default connect(
  mapStateToProps,
  {
    onToggleModal: NotesActions.onToggleModal,
    fetchEmployer: EmployersActions.fetchEmployer,
    getAllLiveJobs: EmployersActions.getAllLiveJobs,
  }
)(withRouter(Content))
