import React from 'react'
import { connect } from 'react-redux'

import { AppState } from 'store/rootReducer'
import AuthActions from 'store/auth/actions'
import DashboardActions from 'store/dashboard/actions'
import { IGoals, IPerformanceTracker, IUser } from 'types/models'

import Sidebar from './components/Sidebar'
import { Content } from './components/Content'

import { BoxFill } from 'styles'
import { BG, Wrapper } from './styles'

type Props = {
  user: IUser
  performanceTracker: IPerformanceTracker
  updateGoals: (user: Partial<IGoals>) => void
  getPerformanceTracker: () => void
  getInvoices: typeof DashboardActions.getInvoices
  moneyTracker: any
}

class DashBoard extends React.Component<Props> {
  componentDidMount(): void {
    this.props.getPerformanceTracker()
    this.props.getInvoices({})
  }

  render() {
    const { user, updateGoals, performanceTracker, moneyTracker, getInvoices } = this.props
    return (
      <BoxFill>
        <BG />
        <Wrapper pb="30px">
          <Sidebar user={user} updateGoals={updateGoals} performanceTracker={performanceTracker} />
          <BoxFill column flex={2.3}>
            <Content moneyTracker={moneyTracker} getInvoices={getInvoices} />
          </BoxFill>
        </Wrapper>
      </BoxFill>
    )
  }
}

const mapStateToProps = (state: AppState) => ({
  user: state.auth.get('user'),
  performanceTracker: state.dashboard.get('performanceTracker').toJS(),
  moneyTracker: state.dashboard.get('moneyTracker').toJS(),
})

export default connect(
  mapStateToProps,
  {
    getInvoices: DashboardActions.getInvoices,
    getPerformanceTracker: DashboardActions.getPerformanceTracker,
    updateGoals: AuthActions.updateUser,
  }
)(DashBoard)
