import Api from './index'
import { IList, IState, ICity } from 'types/models'

class List {
  /**
   * Gets Australian states and territories
   */
  static getStates() {
    return Api.get<IState[]>('/lists/states/')
  }
  /**
   * Gets Australian cities
   */
  static getCities() {
    return Api.get<ICity[]>('/lists/cities/')
  }

  /**
   * Gets job positions
   */
  static getJobPositions() {
    return Api.get<IList[]>('/lists/positions/')
  }

  /**
   * Gets project types
   */
  static getProjectTypes() {
    return Api.get<IList[]>('/lists/sectors/')
  }

  /**
   * Gets locations
   */
  static getLocations() {
    return Api.get<IState[]>('/lists/locations/')
  }

  /**
   * Gets cities meets search string and state id (optional)
   *
   * @param {string} q - query string
   * @param {number} state - state id (optional)
   */

  static searchCities(q: string, state?: number) {
    return Api.post<ICity[]>('/lists/states/cities/autocomplete/', { q, state })
  }

  /**
   * Gets Qualifications
   */
  static getItPackages() {
    return Api.get<IList[]>('/lists/it-packages/')
  }

  /**
   * Gets project values
   */
  static getProjectValues() {
    return Api.get<IList[]>('/lists/project-values/')
  }

  /**
   * Gets public data
   */
  static getPublicEmployers(recruiterId: number) {
    return Api.get(`/public/employers/${recruiterId}/`)
  }

  static getPublicEmployersWithoutId() {
    return Api.get('/public/employers')
  }
}

export default List
