import React from 'react'

import { ICandidate, IEmployer, IUser } from 'types/models'
import { InputEvent } from 'types/helps'

import { Bold, Image } from 'styles'
import { NotImageBox, NotImageBoxChars } from './styles'

type Props = {
  item?: ICandidate | IEmployer | IUser
  chars?: string | string[] | number
  width?: string
  onClick?: (e: InputEvent | any) => any
  contain?: boolean
  textSize?: string
}

const Avatar: React.FC<Props> = ({ item, chars, width, onClick, contain, textSize }) => {
  if (chars)
    return (
      <NotImageBoxChars width={width} height={width} onClick={onClick}>
        <Bold accentColor lh={textSize} fz={textSize}>
          {chars}
        </Bold>
      </NotImageBoxChars>
    )
  let name
  let photoLink
  if (item) {
    if ('firstName' in item) {
      name = item.firstName[0] + item.lastName[0]
      photoLink = item.photoFile ? item.photoFile.link : null
    } else {
      name = item.companyName.split(' ').map(char => char[0])
      photoLink = item.logoFile ? item.logoFile.link : null
    }
  }

  return (
    <NotImageBox width={width} height={width} onClick={onClick}>
      {photoLink ? (
        <Image width={width} height={width} style={{ borderRadius: '50%' }} src={photoLink} contain={contain} pointer alt={photoLink} />
      ) : (
        <Bold width={width} accentColor lh={textSize} fz={textSize} pointer>
          {name}
        </Bold>
      )}
    </NotImageBox>
  )
}

Avatar.defaultProps = {
  width: '72px',
  contain: false,
  textSize: '16px',
}

export default Avatar
