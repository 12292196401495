import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`
      html {
        font-family: 'Mukta', sans-serif;
      }

      body {
        margin: 0;
      }

      p {
        font-family: 'Mukta', sans-serif;
        font-weight: 300;
        margin: 0;
      }

      pre {
        font-family: 'Mukta', sans-serif;
        font-weight: 300;
        margin: 0;
      }

      a {
        text-decoration: none;
      }

      div {
        box-sizing: border-box;
      }

      h1,h2,h3,h4,h5,h6 {
        font-family: 'Mukta', sans-serif;
        margin: 0;
      }

      h3 {
        font-weight: 600;
        font-size: 30px;
        line-height: 20px;
      }

      table, th, td {
        border: 1px solid black;
        border-collapse: collapse;
      }
      
      th {
        text-align: center;    
        padding: 5px;
      }

      td {
        padding: 5px;
        text-align: left;    
      }

      input {
        font-family: 'Mukta', sans-serif;
      }

      select {
        font-family: 'Mukta', sans-serif;
      }

      textarea {
        outline: none;
        resize: none;
      }

      input[type="file"] {
        display: none;
      }

      ::-webkit-input-placeholder {
        color: ${(props: any) => props.theme.main.placeholderColor};
        font-size: 16px;
        font-weight: 100;
      }
      ::-moz-placeholder {
        color: ${props => props.theme.main.placeholderColor};
        font-size: 16px;
        font-weight: 100;
      }
      :-ms-input-placeholder {
        color: ${props => props.theme.main.placeholderColor};
        font-size: 16px;
        font-weight: 100;
      }
      ::placeholder {
        color: ${props => props.theme.main.placeholderColor};
        font-size: 16px;
        font-weight: 100;
      }

      textarea::placeholder {
        color: ${props => props.theme.main.placeholderColor};
        font-size: 15px;
        font-weight: 300;
        font-family: 'Mukta',sans-serif;
      }

      input:required {
        box-shadow: none;
      }
      input:invalid {
        box-shadow: none;
      }

      input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
 
      input[type="number"] {
        -moz-appearance: textfield;
      }

      input[type=checkbox] {
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        outline: none;
	      border: 1px solid #E3E8F1;
        z-index: 2;
        width: 24px;
        height: 24px;
        border-radius: 6px;
        display: block;
        position: relative;
        cursor:pointer;
      }

      input[type=checkbox] + img {
        display: none;
      }
      
      input[type=checkbox]:checked {
        border: 1px solid #3CB593;
      }

      input[type=checkbox]:checked + img {
        display: block;
        position: absolute;
        z-index: 1;
        top: calc(50% - 4.5px);
        left: calc(50% - 5px);
        cursor:pointer;
      }

      .swal2-title {
        font-weight: bold !important;
        color: #292C38 !important;
        font-size: 20px !important;
      }

      .swal2-content {
        font-size: 16px !important;
        color: #9CAAB8 !important;
      }

      .swal2-confirm {
        font-size: 12px !important;
        font-family: 'Mukta', sans-serif;
        width: 100%;
        height: 60px;
        border-radius: 6px !important;
      }

      .swal2-popup {
        font-family: 'Mukta', sans-serif;
        padding: 40px !important;
        width: 400px !important;
      }

      ::-webkit-scrollbar {
        width: 16px;
        height: 16px;
      }

      ::-webkit-scrollbar-thumb {
        width: 10px;
        background: #E3E8F1;
        border: 6px solid rgba(0, 0, 0, 0);
        border-radius: 16px;
        background-clip: padding-box;
      }
    `
