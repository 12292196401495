import React from 'react'
import { connect } from 'react-redux'
import Immutable, { Map } from 'immutable'
import { RouteComponentProps } from 'react-router-dom'
import moment from 'moment'

import { AppState } from 'store/rootReducer'
import PipelineActions from 'store/pipeline/actions'
import NotesActions from 'store/notes/actions'
import MessengerActions from 'store/messenger/actions'
import CandidatesActions from 'store/candidates/actions'
import { ICandidate, IJobPositionsWithMathcedCandidates, IMatched, IMatchedJobPosition, IMatchStatuses } from 'types/models'

import NotesModal from 'containers/Notes'
import CandidateInnerJobPosition from '../CandidateInnerJobPosition'

import Button from 'components/Button'
import StatusDropdown from 'components/StatusDropDown'
import Modal from 'components/Modal'
import DocItem from 'components/UploadInput/docItem'
import Avatar from 'components/Avatar'
import MatchedJobCard from 'components/ForCandidates&Employers/matchedJobCard'
import PotentialJobCard from 'components/ForCandidates&Employers/potentialJobCard'
import Loader from 'components/Loader'
import { SmallLoader } from 'components/Loader'
import EditIcon from 'components/EditIcon'
import Tabs from 'components/Tabs'
import { Email } from 'components/Others'
/* NOT IN MVP */
// import SortBy from 'components/Input/sortBy'
// import Input from 'components/Input'
// import { Search } from 'components/Icons'

import { formatCurrency } from 'utils'
import { Box, BoxFill, BoxCol, Text, Bold, Image, H2, Textarea, SectorsTypeBox } from 'styles'
import {
  FormHeader,
  FormRow,
  BG,
  Wrapper,
  CandidateInfoBox,
  MatchedJobsBox,
  MatchedJobCardWrap,
  WorkPlacesBox,
  TypeItem,
  Separator,
  InfoBox,
  TextNoWrap,
  AddNotes,
  Message,
  NotAddJobPosition,
  NotAddJobPositionTitle,
  ItemBox,
  ItemText,
  PopUpBox,
  StyledSectorsTypeBox,
  Locations,
  ReadyToRelocate,
  ReadySpan,
} from './styles'

import messageIcon from 'assets/icons/message.svg'
import youDidntAddJobPositionsYetIcon from 'assets/images/you-didn’t-add-job-positions-yet.png'

/* NOT IN MVP */
// const SORT_TYPES = [
//   'Match rating',
//   'By position name (A-Z)',
//   'By position name (Z-A)',
//   'By employer name (A-Z)',
//   'By employer name (Z-A)',
// ]

type Params = {
  id: number
}

type Props = {
  isFetching: boolean
  candidate: ICandidate
  potentialJobPositions: Immutable.List<IJobPositionsWithMathcedCandidates>
  matchedPositions: Map<number, IMatchedJobPosition>
  potentialJobPositionsQty: number | null
  pagesQty: number | null
  query: { page: number; perPage: number } | null
  fetchCandidate: typeof CandidatesActions.fetchCandidateStarted
  resetStore: typeof CandidatesActions.resetStore
  match: { params: Params }
  setStatusCandidate: typeof CandidatesActions.setStatusCandidate
  matchCandidate: (JobPositionId: number, CandidateId: number, status?: IMatchStatuses) => void
  changePipelineStatus: typeof PipelineActions.changePipelineStatus
  onToggleModal: typeof NotesActions.onToggleModal
  addNewCandidateOrEmployer: typeof MessengerActions.addNewCandidateOrEmployer
}

type State = {
  job?: IJobPositionsWithMathcedCandidates
  currentSection: number
}

class Candidate extends React.Component<Props & RouteComponentProps, State> {
  state: State = {
    job: undefined,
    currentSection: 0,
  }
  componentWillMount() {
    this.props.fetchCandidate(this.props.match.params.id)
  }
  componentWillUnmount() {
    this.props.resetStore()
  }

  loadMore = () => this.props.fetchCandidate(this.props.match.params.id, ++this.props.query!.page)

  setJob = (job: IJobPositionsWithMathcedCandidates) => this.setState({ job })

  hideModal = () => this.setState({ job: undefined })

  matchCandidate = (jobPositionId: number) => this.props.matchCandidate(jobPositionId, this.props.candidate.id, 1)

  Info = () => {
    const { candidate, history, setStatusCandidate, onToggleModal, addNewCandidateOrEmployer } = this.props
    const editCandidateRoute = () => history.push(`${candidate.id}/edit`)
    const setActiveCandidateStatus = () => setStatusCandidate(candidate.id, false)
    const setHiredCandidateStatus = () => setStatusCandidate(candidate.id, true)

    let candidateId: number | null
    let candidateFullName = ''
    let candidatePosition = ''
    let candidateAvatar = ''

    const addNewCandidate = () => {
      addNewCandidateOrEmployer('candidate', candidateId, candidateFullName, candidatePosition, candidateAvatar)
    }

    const renderItems = (items: Array<{ name: string }>) => {
      if (!items.length)
        return (
          <Box>
            <ItemBox>
              <ItemText>Any</ItemText>
            </ItemBox>
          </Box>
        )
      return (
        <StyledSectorsTypeBox>
          <ItemBox pointer justifyCenter relative ml="5px">
            <Locations>
              <ItemText pointer>{items.length} locations</ItemText>
            </Locations>
            <PopUpBox className="popUpBox">
              {items.map(item => (
                <ItemBox key={item.name}>
                  <ItemText>{item.name}</ItemText>
                </ItemBox>
              ))}
            </PopUpBox>
          </ItemBox>
        </StyledSectorsTypeBox>
      )
    }

    if (candidate) {
      candidateId = candidate.id
      candidateFullName = `${candidate.firstName} ${candidate.lastName}`
      candidatePosition = candidate.Positions && candidate.Positions.length ? candidate.Positions[0].name : ''
      candidateAvatar = candidate.photoFile && candidate.photoFile.link

      const { City } = candidate
      const city = City && City.name.indexOf(City.State.abbr) > 0 ? City.name : `${City.name} ${City.State.abbr}`

      return (
        <CandidateInfoBox>
          <Box absolute top="23px" right="20px">
            <EditIcon onClick={editCandidateRoute} />
          </Box>
          <Box absolute top="23px" left="20px">
            <StatusDropdown onActive={setActiveCandidateStatus} onClose={setHiredCandidateStatus} hired={candidate.hired} />
          </Box>
          <Box shadow2 br="50%">
            <Avatar item={candidate} />
          </Box>
          <BoxCol fill overflow="hidden" alignCenter mt="16px">
            <Bold black xl>
              {candidate.public && <Image style={{ marginBottom: '-2px' }} src={require('assets/icons/user-icon.svg')} />} {candidate.firstName}{' '}
              {candidate.lastName}
            </Bold>
            <Box alignCenter>
              <Box pr="6px" borderRight="1px solid #E3E8F1">
                <Text lh="20px">{city}</Text>
              </Box>
              <Box ml="6px" bc="#D8F0E9" br="6px" padding="4px 8px">
                <Text xs fw="500" secondColor>
                  ${formatCurrency(candidate.minSalary)}
                </Text>
              </Box>
            </Box>
            <Box
              pointer
              alignCenter
              justifyCenter
              width="180px"
              height="52px"
              mt="20px"
              bc="#D5EBFF"
              br="6px"
              padding="4px 8px"
              onClick={() => addNewCandidate()}
            >
              <img src={messageIcon} alt="message" />
              <Message>Message</Message>
            </Box>
          </BoxCol>
          <Separator />
          <FormHeader title="Preferable positions" />
          <BoxFill wrap>
            {candidate.Positions && candidate.Positions.length ? (
              candidate.Positions.map(({ name }) => <TypeItem key={name} text={name} />)
            ) : (
              <TypeItem text="Any" />
            )}
          </BoxFill>

          <Separator />

          <BoxCol fill mt="20px">
            <FormHeader title="Notes" />
            <Box fill defaultBorder smooth height="52px" alignCenter justifyCenter>
              <AddNotes
                secondColor
                xs
                fw="bold"
                pointer
                onClick={() => {
                  onToggleModal(true, candidate.id, 'candidate')
                }}
              >
                {candidate.notesCount === 1 ? `${candidate.notesCount} note` : candidate.notesCount ? `${candidate.notesCount} notes` : 'Add notes'}
              </AddNotes>
            </Box>
          </BoxCol>

          <Separator />
          <FormHeader title="Preferable project types" />
          <BoxFill wrap>
            {candidate.Sectors && candidate.Sectors.length ? (
              candidate.Sectors.map(({ name }) => <TypeItem isSector key={name} text={name} />)
            ) : (
              <TypeItem isSector text="Any" />
            )}
          </BoxFill>
          <Separator />
          <FormHeader title="Personal information" />
          <FormRow
            title="Date of birth"
            value={
              candidate.birthday
                ? `${moment(candidate.birthday).format('l')} (${moment(Date.now()).diff(candidate.birthday, 'years')} y.o.)`
                : 'Date not specified'
            }
          />
          <ReadyToRelocate>
            <ReadySpan>Ready to relocate</ReadySpan>
            {candidate.relocate ? (
              <Box>
                <Text whiteSpace secondColor>
                  Yes
                </Text>
                {candidate.RelocateCity && candidate.RelocateCity.length > 0 && (
                  <SectorsTypeBox width="100%">{renderItems(candidate.RelocateCity)}</SectorsTypeBox>
                )}
              </Box>
            ) : (
              <Text red>No</Text>
            )}
          </ReadyToRelocate>
          <FormRow title="Phone number" value={candidate.phone} />
          <InfoBox>
            <TextNoWrap>Email address</TextNoWrap>
            <Email email={candidate.email} />
          </InfoBox>
          <Separator />
          <FormHeader title="Professional details" />
          <FormRow title="Years of experience" value={candidate.experience + ' years'} />
          <FormRow title="Available from" value={moment(candidate.availability).format('l')} />
          <Separator />
          <FormHeader title="Candidate CV" />
          {candidate.cvFile && candidate.cvFile.length ? (
            <BoxCol>
              <DocItem file={candidate.cvFile[0]} onClick={() => window.open(candidate.cvFile[0].link)} />
            </BoxCol>
          ) : (
            <Text>No CV</Text>
          )}
        </CandidateInfoBox>
      )
    } else {
      return null
    }
  }

  closeJobDeal = (matched: IMatched) => {
    const { changePipelineStatus } = this.props
    changePipelineStatus(matched.JobPositionId, matched.CandidateId, '-1', matched.interviewDate || '', () => {
      this.props.fetchCandidate(this.props.match.params.id)
    })
  }

  notAddJobPosition = () => {
    const { history } = this.props
    return (
      <NotAddJobPosition>
        <img src={youDidntAddJobPositionsYetIcon} alt="not add job position" />
        <NotAddJobPositionTitle>You don't have a suitable job for this candidate</NotAddJobPositionTitle>
        <span>
          Visit Live Jobs and add employers and job positions <br />
          to match candidates with those job positions
        </span>
        <Button width="134px" height="52px" onClick={() => history.push('/live-jobs-new')} title="VISIT LIVE JOBS" />
      </NotAddJobPosition>
    )
  }

  jobPositionsSection = () => {
    const { potentialJobPositions, potentialJobPositionsQty, candidate, matchedPositions, pagesQty, isFetching } = this.props

    if (candidate && candidate.hired) return this.candidateHiredImage()
    let moreItemsCount = 0

    // old version
    // if (potentialJobPositionsQty && pagesQty === query!.page + 1) {
    //   const actualCount = potentialJobPositionsQty - potentialJobPositions.size
    //   if (actualCount < 8) moreItemsCount = actualCount
    // }

    if (potentialJobPositionsQty && pagesQty) {
      moreItemsCount = potentialJobPositionsQty - potentialJobPositions.size
    }

    const sortByDegree = (jobs: any) => Array.from(jobs).sort((a: any, b: any) => b[1].complianceDegree - a[1].complianceDegree)

    return (
      <BoxFill column padding="0 30px 30px">
        {matchedPositions.size ? (
          <BoxFill column>
            <Box mb="10px">
              <Bold black xl>
                Matched ({matchedPositions.size})
              </Bold>
            </Box>
            <MatchedJobsBox>
              {matchedPositions.valueSeq().map((job: any) => (
                <MatchedJobCardWrap>
                  <MatchedJobCard key={job.id} job={job} closeJobDeal={() => this.closeJobDeal(job.Matched)} />
                </MatchedJobCardWrap>
              ))}
            </MatchedJobsBox>
            <Separator />
          </BoxFill>
        ) : null}
        {potentialJobPositions.size ? (
          <BoxFill column>
            <BoxFill mb="20px" spaceBetween alignCenter>
              <Box>
                <BoxCol>
                  <Bold black xl>
                    Potential ({potentialJobPositionsQty})
                  </Bold>
                  <Text xs>Match with job positions from your list</Text>
                </BoxCol>
              </Box>
              {/* NOT IN MVP  */}
              {/* <Box alignCenter>
            <Box width="200px" height="60px">
              <SortBy changeSort={() => {}} currentSort="Match rating" sortTypes={SORT_TYPES} />
            </Box>
            <Box width="300px" ml="13px">
              <Input placeholder="Search by employer or position" rightElement={<Search />} widthRightElement="13px" />
            </Box>
          </Box> */}
            </BoxFill>
            <BoxFill column>
              {potentialJobPositions.size > 0 &&
                potentialJobPositions.valueSeq() &&
                sortByDegree(potentialJobPositions).map((job: any) => (
                  <PotentialJobCard key={job[1].id} job={job[1]} match={this.matchCandidate} onClick={this.setJob} />
                ))}
            </BoxFill>
          </BoxFill>
        ) : candidate && candidate.JobPositions && candidate.JobPositions.length === 0 && matchedPositions.size === 0 ? (
          this.notAddJobPosition()
        ) : null}

        <BoxFill>
          {this.props.query
            ? this.props.pagesQty === this.props.query.page ||
              (potentialJobPositions.size > 0 && moreItemsCount > 0 && (
                <BoxFill mt="14px" height="52px">
                  {isFetching ? (
                    <SmallLoader width="40px" height="40px" />
                  ) : (
                    <Button type="load" onClick={this.loadMore} title={`Show more positions`} />
                  )}
                </BoxFill>
              ))
            : null}
        </BoxFill>
      </BoxFill>
    )
  }

  additionalInfoSection = () => {
    const { candidate } = this.props
    return (
      <BoxFill column padding="0px 30px 20px 30px">
        {!candidate.licenseFiles.length || (
          <BoxFill column>
            <Box>
              <Bold black xl>
                Candidate Licenses
              </Bold>
            </Box>
            <WorkPlacesBox>
              {candidate.licenseFiles.map(license => (
                <DocItem key={license.id} file={license} onClick={() => window.open(license.link)} />
              ))}
            </WorkPlacesBox>
            <Separator />
          </BoxFill>
        )}
        {!candidate.WorkPlaces.length || (
          <BoxFill column>
            <Bold whiteSpace black xl>
              Work experience
            </Bold>
            <WorkPlacesBox>
              {candidate.WorkPlaces.map(work => {
                const { id, companyName, Position, City } = work
                return (id && companyName) || Position || City ? (
                  <BoxCol key={id} defaultBorder smooth padding="20px">
                    <BoxCol mb="14px">
                      <Text black>{companyName && companyName}</Text>
                      <Text s>{Position && Position.name}</Text>
                    </BoxCol>
                    <Box alignCenter>
                      {City ? (
                        <Box borderRight="1px solid #E3E8F1" pr="8px">
                          <Text black s lh="16px">
                            {City.name.indexOf(City.State.abbr) > 0 ? City.name : `${City.name} ${City.State.abbr}`}
                          </Text>
                        </Box>
                      ) : null}
                      <Box ml="8px">
                        <Text black>
                          {work.fromDate
                            ? `${moment(work.fromDate).format('M/YYYY')} - ${
                                work.current ? 'now' : work.toDate && moment(work.toDate).format('M/YYYY')
                              }`
                            : null}{' '}
                          {work.fromDate ? moment(work.fromDate).from((work.toDate && work.toDate) || new Date(), true) : null}
                        </Text>
                      </Box>
                    </Box>
                  </BoxCol>
                ) : null
              })}
            </WorkPlacesBox>
            <Separator />
          </BoxFill>
        )}
        {candidate.addInfo ? (
          <BoxFill column>
            <Bold black xl>
              Candidate description
            </Bold>
            <BoxFill height="500px" mt="10px">
              <Textarea noShadow disabled noBorder value={candidate.addInfo} padding="0" />
            </BoxFill>
          </BoxFill>
        ) : null}
      </BoxFill>
    )
  }

  candidateHiredImage = () => (
    <BoxCol fill smooth>
      <BoxFill column smooth whiteBC alignCenter padding="50px 0" mt="20px">
        <Image width="280px" height="135px" src={require('assets/images/candidate-hired.png')} />
        <Box mt="20px" mb="2px">
          <H2 black fw="bold">
            This candidate is hired
          </H2>
        </Box>
        <Text>
          Change candidate status to start matching him with
          <br /> the job positions from your list
        </Text>
      </BoxFill>
    </BoxCol>
  )

  setSection = (currentSection: number) => this.setState({ currentSection })

  render() {
    const { currentSection, job } = this.state
    const { isFetching, candidate, history } = this.props
    const employerRoute = () => history.push(`/live-jobs/employer/${job ? job.EmployerId : null}`)
    if (isFetching && !candidate) return <Loader />
    return (
      <BoxFill>
        <BG />
        <Wrapper pb="30px">
          {this.Info()}
          <BoxCol overflow="hidden" smooth whiteBC flex={2.3}>
            <Tabs currentSection={currentSection} sections={['Job positions', 'Additional info']} setSection={this.setSection} />
            {currentSection ? this.additionalInfoSection() : this.jobPositionsSection()}
          </BoxCol>
          <Modal isOpen={job} withScroll onRequestClose={this.hideModal} hideCloseIcon>
            <CandidateInnerJobPosition
              closeJobDeal={this.closeJobDeal}
              job={job}
              match={this.matchCandidate}
              employerRoute={employerRoute}
              onClose={this.hideModal}
            />
          </Modal>
          <NotesModal />
        </Wrapper>
      </BoxFill>
    )
  }
}

const mapStateToProps = (state: AppState) => ({
  isFetching: state.currentInnerPage.get('isFetching'),
  candidate: state.currentInnerPage.get('item'),
  matchedPositions: state.currentInnerPage.get('matchedPositions'),
  potentialJobPositions: state.currentInnerPage.get('potentialJobPositions'),
  potentialJobPositionsQty: state.currentInnerPage.get('potentialJobPositionsQty'),
  pagesQty: state.currentInnerPage.get('pagesQty'),
  query: state.currentInnerPage.get('query'),
})

export default connect(
  mapStateToProps,
  {
    fetchCandidate: CandidatesActions.fetchCandidateStarted,
    matchCandidate: CandidatesActions.match,
    resetStore: CandidatesActions.resetStore,
    setStatusCandidate: CandidatesActions.setStatusCandidate,
    changePipelineStatus: PipelineActions.changePipelineStatus,
    onToggleModal: NotesActions.onToggleModal,
    addNewCandidateOrEmployer: MessengerActions.addNewCandidateOrEmployer,
  }
)(Candidate)
