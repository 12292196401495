import React, { useState, useEffect, useCallback } from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { ValidationError } from 'yup'
import isEmpty from 'lodash/isEmpty'
import styled from 'styled-components'

import { AppState } from 'store/rootReducer'
import EmployersActions from 'store/employers/actions'
import { IEmployerContact, IJobPosition } from 'types/models'
import { ISelectListItem } from 'types/forms'
import { InputEvent } from 'types/helps'

import Stepper from 'components/Stepper'
import FormFrame from 'components/FormStepFrame'
import FAQ from 'components/faqSteps'
import Modal from 'components/Modal'
import Button from 'components/Button'
import Loader from 'components/Loader'

import { FirstStep } from './steps/1'
import { SecondStep } from './steps/2'
import { ThirdStep } from './steps/3'

import { addPublicJobPositionSchemaFirstStep, addJobPositionSchemaSecondStep, formatErrors } from 'services/validation'

import { BoxFill, Box } from 'styles'
import theme from 'styles/theme'
import { ModalTitle, ModalSubTitle, BottonWrap, LoaderWrap } from './styles'

import textOfStep from './steps/textOfStep'

const Wrap = styled(Box).attrs({})`
  @media (max-width: 768px) {
    flex-direction: column;
  }
`

const Steps = ['Step 1. Basic information', 'Step 2. Hiring manager info', 'Step 3. Job description (optional)']

type State = {
  currentStep: number
  form: Partial<IJobPosition>
  errors: any
  contactForm: Partial<IEmployerContact>
  contactFormSelection: any
}

const AddJobPositionByLink: React.FC<RouteComponentProps & State> = ({ history }) => {
  const dispatch = useDispatch()
  const contacts = useSelector((state: AppState) => state.employers.get('publicEmployersContacts'))
  const isFetching = useSelector((state: AppState) => state.employers.get('isFetching'))
  const isAddedPublicJobPosition = useSelector((state: AppState) => state.employers.get('isAddedPublicJobPosition'))

  const [currentStep, setCurrentStep] = useState(1)
  const [form, setForm] = useState<any>({})
  const [contactForm, setContactForm] = useState({})
  const [contactFormSelection, setContactFormSelection] = useState(null)
  const [errors, setErrors] = useState<any>({})

  const recruiterId = window.location.pathname.substring(26)

  useEffect(() => {
    if (form && form.Companies && form.Companies.id) {
      dispatch(EmployersActions.getPublicEmployersContacts(form.Companies.id))
    }
  }, [dispatch, form && form.Companies && form.Companies.id])

  const prevStep = () => setCurrentStep(currentStep - 1)

  const nextStep = () => {
    if (currentStep === 2 && !contactFormSelection && isEmpty(contactForm)) {
      return setErrors({
        haveSelectedContat: true,
      })
    } else if (contactFormSelection) {
      setErrors({})
      setCurrentStep(currentStep + 1)
    }

    const validatorSteps: any = {
      1: { validator: addPublicJobPositionSchemaFirstStep, data: form },
      2: { validator: addJobPositionSchemaSecondStep, data: contactForm },
    }
    const validator = validatorSteps[currentStep].validator
    validator
      .validate(validatorSteps[currentStep].data, { abortEarly: false })
      .then(() => {
        setErrors({})
        setCurrentStep(currentStep + 1)
      })
      .catch((err: ValidationError) => {
        setErrors(formatErrors(err))
      })
  }

  const onChangeFormField = (e: InputEvent & ISelectListItem & any, type?: string) => {
    if (type) return setForm({ ...form, [type]: e })
    const {
      name,
      value,
      validity: { valid },
    } = e.currentTarget
    if (valid) {
      setForm({ ...form, [name]: value })
    }
  }

  const onChangeContactField = (e: InputEvent & ISelectListItem) => {
    const {
      name,
      value,
      validity: { valid },
    } = e.target
    if (valid) {
      setContactForm({ ...contactForm, [name]: value })
    }
  }

  const onChangeContactSelection = (item: any) => {
    setContactFormSelection(item)
    setErrors({})
  }

  const createJobPosition = useCallback(
    (form, contactForm, contactFormSelection) => {
      const jobData: any = { ...form }
      let newContactInfo: undefined | Partial<IEmployerContact>
      if (contactFormSelection) {
        jobData.EmployerContactId = contactFormSelection.id
      } else {
        newContactInfo = contactForm
      }
      if (recruiterId) {
        dispatch(EmployersActions.addPublicJobPosition(+recruiterId, form.Companies.id, jobData, newContactInfo))
      } else {
        dispatch(EmployersActions.addPublicJobPositionWithoutId(form.Companies.id, jobData, newContactInfo))
      }
    },
    [dispatch]
  )

  const renderCurrentStep = () => {
    switch (currentStep) {
      case 1:
        return <FirstStep form={form} errors={errors} onChangeField={onChangeFormField} recruiterId={recruiterId} />
      case 2:
        return (
          <SecondStep
            form={contactForm}
            selection={contactFormSelection}
            list={contacts}
            errors={errors}
            onChangeField={onChangeContactField}
            onChangeSelection={onChangeContactSelection}
          />
        )
      case 3:
        return <ThirdStep description={form.description} onChangeField={onChangeFormField} />
      default:
        return null
    }
  }

  const isLastStep = currentStep === Steps.length

  return (
    <BoxFill bc={theme.main.homeBackground} column>
      <Stepper currentStep={currentStep} steps={Steps} title="Add job position" />
      <Wrap mt="-25vh">
        <FormFrame
          currentStep={currentStep}
          maxStep={Steps.length}
          btnText={isLastStep ? 'ADD JOB POSITION' : 'NEXT STEP'}
          title={textOfStep(currentStep)!.title}
          text={textOfStep(currentStep)!.subTitle}
          nextStep={isLastStep ? () => createJobPosition(form, contactForm, contactFormSelection) : nextStep}
          back={prevStep}
        >
          {renderCurrentStep()}
        </FormFrame>
        <FAQ title={textOfStep(currentStep)!.faq.title} text={textOfStep(currentStep)!.faq.text} />
      </Wrap>
      {isFetching ? (
        <LoaderWrap>
          <Loader height="400px" withoutBg />
        </LoaderWrap>
      ) : (
        isAddedPublicJobPosition && (
          <Modal isOpen={isAddedPublicJobPosition} hideCloseIcon onRequestClose={() => window.location.reload()}>
            <ModalTitle>Your job has been added to the platform</ModalTitle>
            <ModalSubTitle>We will be in touch within 24 hours for when a job is added</ModalSubTitle>
            <BottonWrap>
              <Button width="198px" height="52px" title="ADD ANOTHER JOB" onClick={() => window.location.reload()} />
            </BottonWrap>
          </Modal>
        )
      )}
    </BoxFill>
  )
}

export default withRouter(AddJobPositionByLink)
