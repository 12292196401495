import React from 'react'
import Select from 'react-select'
import { State } from 'react-select/lib/Select'
import AsyncSelect from 'react-select/lib/Async'
import styled from 'styled-components'

import ApiList from 'api/list'

import { IList, ICity } from 'types/models'

import theme from 'styles/theme'
import { BoxFullFill, BoxFill, Label, Box, Text } from 'styles'

const Wrap = styled(BoxFill).attrs({})`
  align-items: flex-end;
`

const colourStyles = (error: any) => ({
  container: (styles: any) => ({ ...styles, width: '100%' }),
  control: (styles: any, state: State<any>) => ({
    ...styles,
    height: '100%',
    '&:hover': null,
    border: `1px solid ${theme.main.borderInputColor}`,
    borderColor: `${state.isFocused ? theme.main.accentColor : error ? theme.main.red : null} !important`,
    boxShadow: 'none',
  }),
  menu: (styles: any) => ({ ...styles, zIndex: '10', boxShadow: '0px 3px 17px rgba(150, 170, 180, 0.3)' }),
  valueContainer: (styles: any) => ({
    ...styles,
    width: '100%',
    padding: '11px 8px',
  }),
  multiValue: (styles: any) => ({
    ...styles,
    borderRadius: '6',
  }),
  multiValueRemove: (styles: any) => ({
    ...styles,
    '&:hover': {
      cursor: 'pointer',
    },
  }),
  placeholder: (styles: any) => ({
    ...styles,
    fontSize: '18',
    color: theme.main.inputLabelTextColor,
    fontWeight: '300',
  }),
})

type Props = {
  value: ICity
  placeholder?: string
  labelCity?: string
  labelState?: string
  name?: string
  onChange?: (e: any) => void
  error?: string | false | object
  isShowError?: boolean
}

const CityStateSelect: React.FC<Props> = ({ value, onChange, labelCity, labelState, name, placeholder, error, isShowError }) => {
  const filterCities = (inputValue: string, options: IList[]) => {
    return options.filter(i => i.name.toLowerCase().includes(inputValue.toLowerCase())).sort((a, b) => (a.name > b.name ? 1 : -1))
  }

  const promiseOptions = (inputValue: string = '') =>
    new Promise(resolve => ApiList.searchCities(inputValue).then(({ data }) => resolve(filterCities(inputValue, data))))

  const renderMessageError = () => {
    return error && !isShowError ? null : (
      <Box pt="5px">
        <Text red s>
          {error}
        </Text>
      </Box>
    )
  }
  return (
    <BoxFullFill column>
      <Wrap>
        <Box flex={1.5} column>
          <Label htmlFor="city">{labelCity}</Label>
          <AsyncSelect
            value={value}
            name={name}
            id="city"
            styles={colourStyles(error)}
            components={{ IndicatorSeparator: null }}
            getOptionLabel={option => option.name}
            getOptionValue={option => option.id}
            cacheOptions
            defaultOptions
            loadOptions={promiseOptions}
            placeholder={placeholder}
            onChange={onChange}
          />
        </Box>
        <Box ml="14px" column flex={1}>
          <Label htmlFor="state">{labelState}</Label>
          <Select
            value={value}
            name={name}
            id="state"
            placeholder=""
            styles={colourStyles(error)}
            components={{ IndicatorSeparator: null, DropdownIndicator: null }}
            getOptionLabel={option => option.State.name}
            getOptionValue={option => option.State.id}
            isDisabled
          />
        </Box>
      </Wrap>
      {renderMessageError()}
    </BoxFullFill>
  )
}

CityStateSelect.defaultProps = {
  isShowError: true,
  placeholder: '',
  labelCity: 'City (state will be selected automatically)',
  labelState: 'State',
}

export default CityStateSelect
