import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { ValidationError } from 'yup'
import { connect } from 'react-redux'
import isEmpty from 'lodash/isEmpty'

import { AppState } from 'store/rootReducer'
import EmployersActions from 'store/employers/actions'
import { ISelectListItem } from 'types/forms'
import { IEmployerContact, IJobPosition } from 'types/models'
import { InputEvent } from 'types/helps'

import Stepper from 'components/Stepper'
import FormFrame from 'components/FormStepFrame'
import FAQ from 'components/faqSteps'
import FirstStep from './steps/1'
import SecondStep from './steps/2'
import ThirdStep from './steps/3'
import textOfStep from './steps/textOfStep'

import { addJobPositionSchemaFirstStep, addJobPositionSchemaSecondStep, formatErrors } from 'services/validation'

import { BoxFill, Box } from 'styles'
import theme from 'styles/theme'

const Steps = ['Step 1. Basic information', 'Step 2. Hiring manager info', 'Step 3. Job description (optional)']
type Params = {
  id: number
}

type Props = {
  createJobPosition: typeof EmployersActions.createJob
  match: { params: Params }
  contacts: IEmployerContact[]
  getContacts: (id: number) => void
  fetchEmployer: typeof EmployersActions.fetchEmployer
}
type State = {
  currentStep: number
  form: Partial<IJobPosition>
  errors: any
  contactForm: Partial<IEmployerContact>
  contactFormSelection: any
}

/* TODO:! maybe need one component that will controle all steps for each similar flows
 * or add abstract class
 */
class AddJobPosition extends React.Component<Props & RouteComponentProps, State> {
  state: State = {
    currentStep: 1,
    form: {},
    contactForm: {},
    contactFormSelection: null,
    errors: {},
  }

  componentDidMount() {
    this.props.fetchEmployer(this.props.match.params.id)
  }

  renderCurrentStep = () => {
    const { contacts } = this.props
    const { contactForm, currentStep, form, errors, contactFormSelection } = this.state
    switch (currentStep) {
      case 1:
        return <FirstStep form={form} errors={errors} onChangeField={this.onChangeFormField} />
      case 2:
        return (
          <SecondStep
            form={contactForm}
            selection={contactFormSelection}
            list={contacts}
            errors={errors}
            onChangeField={this.onChangeContactField}
            onChangeSelection={this.onChangeContactSelection}
          />
        )
      case 3:
        return <ThirdStep description={form.description} onChangeField={this.onChangeFormField} />
      default:
        return null
    }
  }

  nextStep = () => {
    const { currentStep, form, contactForm, contactFormSelection } = this.state
    if (currentStep === 2 && !contactFormSelection && isEmpty(contactForm)) {
      return this.setState({
        errors: {
          haveSelectedContat: true,
        },
      })
    } else if (contactFormSelection) {
      this.setState({ errors: {}, currentStep: ++this.state.currentStep })
    }
    const validatorSteps: any = {
      1: { validator: addJobPositionSchemaFirstStep, data: form },
      2: { validator: addJobPositionSchemaSecondStep, data: contactForm },
    }
    const validator = validatorSteps[currentStep].validator
    validator
      .validate(validatorSteps[currentStep].data, { abortEarly: false })
      .then(() => this.setState({ errors: {}, currentStep: ++this.state.currentStep }))
      .catch((err: ValidationError) => this.setState({ ...this.state, errors: formatErrors(err) }))
  }

  prevStep = () => this.setState({ currentStep: --this.state.currentStep })

  onChangeFormField = (e: InputEvent & ISelectListItem & any, type?: string) => {
    if (type) return this.setState({ form: { ...this.state.form, [type]: e } })
    const {
      name,
      value,
      validity: { valid },
    } = e.currentTarget
    if (valid) {
      this.setState({ form: { ...this.state.form, [name]: value } })
    }
  }

  onChangeContactField = (e: InputEvent & ISelectListItem) => {
    const {
      name,
      value,
      validity: { valid },
    } = e.target
    if (valid) {
      this.setState({ contactForm: { ...this.state.contactForm, [name]: value } })
    }
  }

  onChangeContactSelection = (item: any) => {
    this.setState({ contactFormSelection: item, errors: {} })
  }

  createJobPosition = () => {
    const { form, contactFormSelection, contactForm } = this.state
    const { createJobPosition, history, match } = this.props
    const jobData: any = { ...form }
    let newContactInfo: undefined | Partial<IEmployerContact>
    if (contactFormSelection) {
      jobData.EmployerContactId = contactFormSelection.id
    } else {
      newContactInfo = contactForm
    }
    createJobPosition(match.params.id, jobData, newContactInfo)
    history.goBack()
  }

  render() {
    const { currentStep } = this.state
    const { history } = this.props
    const isLastStep = currentStep === Steps.length
    const goBack = () => history.goBack()
    return (
      <BoxFill bc={theme.main.homeBackground} column>
        <Stepper currentStep={currentStep} steps={Steps} title="Add job position" onExit={goBack} />
        <Box mt="-25vh">
          <FormFrame
            currentStep={currentStep}
            maxStep={Steps.length}
            btnText={isLastStep ? 'ADD JOB POSITION' : 'NEXT STEP'}
            title={textOfStep(currentStep)!.title}
            text={textOfStep(currentStep)!.subTitle}
            nextStep={isLastStep ? this.createJobPosition : this.nextStep}
            back={this.prevStep}
          >
            {this.renderCurrentStep()}
          </FormFrame>
          <FAQ title={textOfStep(currentStep)!.faq.title} text={textOfStep(currentStep)!.faq.text} />
        </Box>
      </BoxFill>
    )
  }
}

const mapStateToProps = (state: AppState) => ({
  contacts: state.currentInnerPage.getIn(['item', 'EmployerContacts']),
})

export default connect(
  mapStateToProps,
  {
    createJobPosition: EmployersActions.createJob,
    fetchEmployer: EmployersActions.fetchEmployer,
  }
)(AddJobPosition)
