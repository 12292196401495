import { call, put, takeLatest, delay } from 'redux-saga/effects'

import EmployersActions, {
  FETCH_ALL_EMPLOYERS_STARTED,
  FETCH_EMPLOYER_STARTED,
  CREATE_EMPLOYER_STARTED,
  CREATE_EMPLOYER_CONTACT_STARTED,
  UPDATE_EMLOYER_STARTED,
  DELETE_EMLOYER_STARTED,
  CREATE_JOB_STARTED,
  FETCH_JOB_POSITION,
  SET_STATUS_JOB_POSITION,
  UPDATE_JOB_POSITION_STARTED,
  DELETE_JOB_POSITION_STARTED,
  CREATE_EMPLOYER_AND_EMPLOYER_CONTACT_STARTED,
  DELETE_EMPLOYER_CONTACT_STARTED,
  UPDATE_EMPLOYER_CONTACT_STARTED,
  FETCH_JOBS_BY_EMPLOYER_ID,
  GET_ALL_LIVE_JOBS,
  GET_PUBLIC_EMPLOYERS_CONTACTS,
  ADD_PUBLIC_JOB_POSITION,
  ADD_PUBLIC_JOB_POSITION_WITHOUT_ID,
} from './actions'

import EmployersApi from 'api/employers'

import { smoothScroll } from 'utils'
import { formatErrorsForResponse } from 'services/validation'

function* fetchEmplyersFlow(action: ReturnType<typeof EmployersActions.fetchEmployers>) {
  try {
    const { data } = yield call(EmployersApi.getLiveJobs, action.payload)
    yield put(EmployersActions.fetchEmployersSuccess(data))
  } catch (err) {
    yield put(EmployersActions.fetchEmployersFail(err))
  }
}

function* fetchEmployerFlow(action: ReturnType<typeof EmployersActions.fetchEmployer>) {
  try {
    const { employerId, page, perPage } = action.payload
    const { data } = yield call(EmployersApi.getEmployers, employerId, page, perPage)
    yield put(EmployersActions.fetchEmployerSuccess(data))
  } catch (err) {
    yield put(EmployersActions.fetchEmployerFail(err))
  }
}

function* createEmployerFlow(action: ReturnType<typeof EmployersActions.createEmployer>) {
  try {
    const form = Object.assign({}, action.payload, {
      CityId: action.payload.City!.id,
    })
    const { data } = yield call(EmployersApi.createEmployer, form)
    yield put(EmployersActions.createEmployerSuccess(data))
  } catch (err) {
    yield put(EmployersActions.createEmployerFail(err))
  }
}

function* updateEmployerFlow(action: ReturnType<typeof EmployersActions.updateEmployer>) {
  try {
    const { City, website, phone } = action.payload.employer
    const form = Object.assign({}, action.payload.employer, {
      CityId: City!.id,
      website: website || '',
      phone: phone || '',
    })
    const { data } = yield call(EmployersApi.updateEmployer, action.payload.id, form)
    yield put(EmployersActions.updateEmployerSuccess(data))
    smoothScroll()
    yield delay(3000)
    yield put(EmployersActions.clearNotification())
  } catch (err) {
    yield put(EmployersActions.updateEmployerFail(err))
  }
}

function* deleteEmployerFlow(action: ReturnType<typeof EmployersActions.deleteEmployer>) {
  try {
    yield call(EmployersApi.deleteEmployer, action.payload)
    yield put(EmployersActions.deleteEmployerSuccess())
  } catch (err) {
    yield put(EmployersActions.deleteEmployerFail(err))
  }
}

function* createEmployerContactFlow(action: ReturnType<typeof EmployersActions.createEmployerContact>) {
  try {
    const { contact, employerId } = action.payload
    const form = Object.assign({}, contact)
    const { data } = yield call(EmployersApi.createEmployerContact, employerId, form)
    yield put(EmployersActions.createEmployerContactSuccess(data))
  } catch (err) {
    const { notValidFields } = err.response!.data!
    if (notValidFields) {
      const errors = formatErrorsForResponse(notValidFields)
      yield put(EmployersActions.createEmployerContactFail(errors))
    }
  }
}

function* updateEmployerContactFlow(action: ReturnType<typeof EmployersActions.updateEmployerContact>) {
  try {
    const { contact, contactId } = action.payload
    const form = Object.assign({}, contact)
    yield call(EmployersApi.editEmployerContact, contactId, form)
    yield put(EmployersActions.updateEmployerContactSuccess())
    yield delay(3000)
    yield put(EmployersActions.clearNotification())
    // yield put(EmployersActions.createEmployerSuccess(data))
  } catch (err) {
    console.log('err: ', err)
    // yield put(EmployersActions.createEmployerFail(err))
  }
}

function* deleteEmployerContactFlow(action: ReturnType<typeof EmployersActions.deleteEmployerContact>) {
  try {
    yield call(EmployersApi.deleteEmployerContact, action.payload)
    yield put(EmployersActions.deleteEmployerContactSuccess(action.payload))
  } catch (err) {
    if (err.response && err.response.status === 404) {
      yield put(EmployersActions.deleteEmployerContactFail(err.response.data.message))
    }
    smoothScroll()
  }
}

function* createEmployerAndContactFlow(action: ReturnType<typeof EmployersActions.createEmployerAndContact>) {
  try {
    const formEmployer = Object.assign({}, action.payload.employer, {
      CityId: action.payload.employer.City!.id,
    })
    const { data } = yield call(EmployersApi.createEmployer, formEmployer)
    const formContact = Object.assign({}, action.payload.contact)
    yield call(EmployersApi.createEmployerContact, data.id, formContact)
    yield put(EmployersActions.createEmployerSuccess(data))
  } catch (err) {
    // TODO: need to fix the error handling that the server sends
    yield put(EmployersActions.createEmployerFail(err))
  }
}

function* createJobPositionFlow(action: ReturnType<typeof EmployersActions.createJob>) {
  const {
    job: { City, Position, Sectors, salary, ItPackages, ProjectValues },
    employerId,
  } = action.payload
  const sectors: any = Sectors
  try {
    const form = Object.assign({}, action.payload.job, {
      CityId: City!.id,
      StateId: City!.State.id,
      PositionId: Position!.id,
      SectorIds: (sectors && sectors.map((el: any) => el.id)) || [],
      salary: Number(salary),
      ItPackageIds: ItPackages ? ItPackages!.map((item: any) => item.id) : [],
      ProjectValueIds: ProjectValues ? ProjectValues!.map((item: any) => item.id) : [],
    })
    if (action.payload.contact) {
      const { data: contact } = yield call(EmployersApi.createEmployerContact, action.payload.employerId, action.payload.contact)
      form.EmployerContactId = contact.id
    }
    delete form.ProjectValues
    delete form.ItPackages
    const { data } = yield call(EmployersApi.createJobPosition, employerId, form)
    yield put(EmployersActions.createJobSuccess(data))
    yield put(EmployersActions.fetchEmployers({}))
    yield put(EmployersActions.getAllLiveJobs())
  } catch (err) {
    yield put(EmployersActions.createJobFail(err.response.data))
  }
}

function* fetchJobPositionFlow(action: ReturnType<typeof EmployersActions.fetchJobWithCandidates>) {
  try {
    const { jobPositionId, page, perPage } = action.payload
    const { data } = yield call(EmployersApi.getJobPositionWithPotentialCandidates, jobPositionId, page, perPage)
    if (page) {
      yield put(EmployersActions.fetchNextPageJobWithCandidates(data))
    } else {
      yield put(EmployersActions.fetchJobWithCandidatesSuccess(data))
    }
  } catch (err) {
    yield put(EmployersActions.fetchJobWithCandidatesFail(err))
  }
}

function* updateJobPositionFlow(action: ReturnType<typeof EmployersActions.updateJob>) {
  try {
    const {
      job: { City, Position, Sectors, salary, closed, description, ItPackages, ProjectValues },
    } = action.payload

    const sectors: any = Sectors

    const form = {
      ...action.payload.job,
      salary: Number(salary),
      closed: Boolean(closed),
      PositionId: Number(Position!.id),
      CityId: Number(City!.id),
      SectorIds: sectors ? sectors.map((el: any) => el.id) : [],
      ItPackageIds: ItPackages ? ItPackages.map((item: any) => item.id) : [],
      ProjectValueIds: ProjectValues ? ProjectValues.map((item: any) => item.id) : [],
      // managerPhone: action.payload.job.managerPhone || '',
      // managerPosition: action.payload.job.managerPosition || '',
      description: description || '',
    }

    delete form.ProjectValues
    delete form.ItPackages

    const { data } = yield call(EmployersApi.updateJobPosition, action.payload.id, form)
    yield put(EmployersActions.updateJobSuccess(action.payload.id, data))
    smoothScroll()
    yield delay(3000)
    yield put(EmployersActions.clearNotification())
  } catch (err) {
    yield put(EmployersActions.updateJobFail(err))
  }
}

function* setStatusJobPositionFlow(action: ReturnType<typeof EmployersActions.setStatusJobPosition>) {
  try {
    const { data } = yield call(EmployersApi.setStatusJobPosition, action.payload.id, action.payload.value)
    yield put(EmployersActions.setStatusJobPositionSuccess(data))
  } catch (err) {
    yield put(EmployersActions.setStatusJobPositionFail(err))
  }
}

function* deleteJobPositionFlow(action: ReturnType<typeof EmployersActions.deleteJob>) {
  try {
    yield call(EmployersApi.deleteJobPosition, action.payload)
    yield put(EmployersActions.deleteJobSuccess(action.payload))
  } catch (err) {
    yield put(EmployersActions.updateJobFail(err))
  }
}

function* fetchJobsByEmployerIdFlow(action: ReturnType<typeof EmployersActions.fetchJobsByEmployerId>) {
  try {
    const { data } = yield call(EmployersApi.getJobsByEmployerId, action.payload)
    yield put(EmployersActions.fetchJobsByEmployerIdSuccess(data.jobPositions))
  } catch (err) {
    // yield put(EmployersActions.updateJobFail(err))
  }
}

function* getAllLiveJobsFlow(action: ReturnType<typeof EmployersActions.getAllLiveJobs>) {
  try {
    const { data } = yield call(EmployersApi.getAllLiveJobs, action.payload)
    yield put(EmployersActions.getAllLiveJobsSuccess(data))
  } catch (err) {
    yield put(EmployersActions.getAllLiveJobsFail(err))
  }
}

function* getPublicEmployersContactsFlow(action: ReturnType<typeof EmployersActions.getPublicEmployersContacts>) {
  try {
    const { data } = yield call(EmployersApi.getPublicEmployersContacts, action.payload)
    yield put(EmployersActions.getPublicEmployersContactsSuccess(data))
  } catch (err) {
    yield put(EmployersActions.getPublicEmployersContactsFail(err))
  }
}

function* addPublicJobPositionFlow(action: ReturnType<typeof EmployersActions.addPublicJobPosition>) {
  const {
    job: { City, Position, Sectors, salary, ItPackages, ProjectValues },
    employerId,
    recruiterId,
  } = action.payload
  const sectors: any = Sectors
  try {
    const form = Object.assign({}, action.payload.job, {
      EmployerId: employerId,
      CityId: City!.id,
      StateId: City!.State.id,
      PositionId: Position!.id,
      SectorIds: (sectors && sectors.map((el: any) => el.id)) || [],
      salary: Number(salary),
      ItPackageIds: ItPackages ? ItPackages!.map((item: any) => item.id) : [],
      ProjectValueIds: ProjectValues ? ProjectValues!.map((item: any) => item.id) : [],
    })
    if (action.payload.contact) {
      const { data: contact } = yield call(EmployersApi.createEmployerPublicContact, action.payload.employerId, action.payload.contact)
      form.EmployerContactId = contact.id
    }
    delete form.ProjectValues
    delete form.ItPackages
    delete form.Sectors
    yield call(EmployersApi.createPublicJobPosition, form, recruiterId)
    yield put(EmployersActions.addPublicJobPositionSuccess())
  } catch (err) {
    yield put(EmployersActions.addPublicJobPositionFail(err.response.data))
  }
}

function* addPublicJobPositionWithoutIdFlow(action: ReturnType<typeof EmployersActions.addPublicJobPosition>) {
  const {
    job: { City, Position, Sectors, salary, ItPackages, ProjectValues },
    employerId,
  } = action.payload
  const sectors: any = Sectors
  try {
    const form = Object.assign({}, action.payload.job, {
      EmployerId: employerId,
      CityId: City!.id,
      StateId: City!.State.id,
      PositionId: Position!.id,
      SectorIds: (sectors && sectors.map((el: any) => el.id)) || [],
      salary: Number(salary),
      ItPackageIds: ItPackages ? ItPackages!.map((item: any) => item.id) : [],
      ProjectValueIds: ProjectValues ? ProjectValues!.map((item: any) => item.id) : [],
    })
    if (action.payload.contact) {
      const { data: contact } = yield call(EmployersApi.createEmployerPublicContact, action.payload.employerId, action.payload.contact)
      form.EmployerContactId = contact.id
    }
    delete form.ProjectValues
    delete form.ItPackages
    delete form.Sectors
    yield call(EmployersApi.createPublicJobPositionWithoutId, form)
    yield put(EmployersActions.addPublicJobPositionWithoutIdSuccess())
  } catch (err) {
    yield put(EmployersActions.addPublicJobPositionWithoutIdFail(err.response.data))
  }
}

export default function* watchEmployers() {
  yield takeLatest(FETCH_ALL_EMPLOYERS_STARTED, fetchEmplyersFlow)
  yield takeLatest(FETCH_EMPLOYER_STARTED, fetchEmployerFlow)
  yield takeLatest(CREATE_EMPLOYER_STARTED, createEmployerFlow)
  yield takeLatest(CREATE_EMPLOYER_CONTACT_STARTED, createEmployerContactFlow)
  yield takeLatest(UPDATE_EMPLOYER_CONTACT_STARTED, updateEmployerContactFlow)
  yield takeLatest(CREATE_EMPLOYER_AND_EMPLOYER_CONTACT_STARTED, createEmployerAndContactFlow)
  yield takeLatest(UPDATE_EMLOYER_STARTED, updateEmployerFlow)
  yield takeLatest(DELETE_EMLOYER_STARTED, deleteEmployerFlow)
  yield takeLatest(CREATE_JOB_STARTED, createJobPositionFlow)
  yield takeLatest(FETCH_JOB_POSITION, fetchJobPositionFlow)
  yield takeLatest(SET_STATUS_JOB_POSITION, setStatusJobPositionFlow)
  yield takeLatest(UPDATE_JOB_POSITION_STARTED, updateJobPositionFlow)
  yield takeLatest(DELETE_JOB_POSITION_STARTED, deleteJobPositionFlow)
  yield takeLatest(DELETE_EMPLOYER_CONTACT_STARTED, deleteEmployerContactFlow)
  yield takeLatest(FETCH_JOBS_BY_EMPLOYER_ID, fetchJobsByEmployerIdFlow)
  yield takeLatest(GET_ALL_LIVE_JOBS, getAllLiveJobsFlow)
  yield takeLatest(GET_PUBLIC_EMPLOYERS_CONTACTS, getPublicEmployersContactsFlow)
  yield takeLatest(ADD_PUBLIC_JOB_POSITION, addPublicJobPositionFlow)
  yield takeLatest(ADD_PUBLIC_JOB_POSITION_WITHOUT_ID, addPublicJobPositionWithoutIdFlow)
}
