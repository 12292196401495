import React from 'react'
import Select from 'react-select'
import isEmpty from 'lodash/isEmpty'
import { State } from 'react-select/lib/Select'

import { ISelectListItem } from 'types/forms'
import { IEmployerContact } from 'types/models'
import { InputEvent } from 'types/helps'

import Input from 'components/Input'
import AddItemIcon from 'components/AddItemIcon'

import theme from 'styles/theme'
import { Box, BoxFill, Text } from 'styles'

const colourStyles = (error: any) => ({
  container: (styles: any) => ({ ...styles, width: '100%' }),
  control: (styles: any, state: State<any>) => ({
    ...styles,
    height: '100%',
    '&:hover': null,
    border: `1px solid ${theme.main.borderInputColor}`,
    borderColor: `${state.isFocused ? theme.main.accentColor : error ? theme.main.red : null} !important`,
    boxShadow: 'none',
  }),
  menu: (styles: any) => ({ ...styles, zIndex: '10', boxShadow: '0px 3px 17px rgba(150, 170, 180, 0.3)' }),
  valueContainer: (styles: any) => ({
    ...styles,
    width: '100%',
    padding: '11px 8px',
  }),
  multiValue: (styles: any) => ({
    ...styles,
    borderRadius: '6',
  }),
  multiValueRemove: (styles: any) => ({
    ...styles,
    '&:hover': {
      cursor: 'pointer',
    },
  }),
  placeholder: (styles: any) => ({
    ...styles,
    fontSize: '18',
    color: theme.main.inputLabelTextColor,
    fontWeight: '300',
  }),
})

type Props = {
  form: Partial<IEmployerContact>
  errors: any
  selection: any
  list: IEmployerContact[]
  onChangeField: (e: InputEvent & ISelectListItem) => void
  onChangeSelection: (item: any) => void
}

type LocalState = {
  addContact: boolean
}

class Second extends React.Component<Props, LocalState> {
  state = {
    addContact: false,
  }

  onToggleShowForm = () => {
    const { onChangeSelection, selection } = this.props
    if (!isEmpty(selection)) onChangeSelection(null)
    this.setState({ addContact: !this.state.addContact })
  }

  handleSelectContact = (contact: any) => {
    const { onChangeSelection } = this.props
    const { addContact } = this.state
    if (addContact) this.onToggleShowForm()
    onChangeSelection(contact)
  }

  render() {
    const { onChangeField, errors, form, selection, list } = this.props
    return (
      <BoxFill column>
        <BoxFill mb="20px">
          <Select
            name="contacts"
            value={selection}
            styles={colourStyles(errors.haveSelectedContat)}
            placeholder="Choose hiring manager contact"
            components={{ IndicatorSeparator: null }}
            getOptionLabel={(option: any) => option!.name}
            getOptionValue={(option: any) => `${option!.id}`}
            options={list}
            onChange={this.handleSelectContact}
          />
        </BoxFill>
        {errors.haveSelectedContat ? (
          <Box pt="5px">
            <Text red s>
              Please set hiring manager information
            </Text>
          </Box>
        ) : null}
        <BoxFill mb="20px">
          <Text>OR</Text>
        </BoxFill>
        {this.state.addContact ? (
          <React.Fragment>
            <BoxFill mb="20px">
              <Box flex={1} column mr="10px">
                <Input value={form.name} error={errors.name} onChange={onChangeField} name={`name`} label="Business contact name" />
              </Box>
              <Box flex={1} column ml="10px">
                <Input
                  value={form.position}
                  error={errors.position}
                  onChange={onChangeField}
                  name={`contactPosition`}
                  label="Business contact position (optional)"
                />
              </Box>
            </BoxFill>
            <BoxFill mb="20px">
              <Box flex={1} column mr="10px">
                <Input value={form.email} error={errors.email} onChange={onChangeField} name={`email`} label="Business contact email" />
              </Box>
              <Box flex={1} column ml="10px">
                <Input value={form.phone} error={errors.phone} onChange={onChangeField} name={`phone`} label="Best phone number (optional)" />
              </Box>
            </BoxFill>
          </React.Fragment>
        ) : (
          <BoxFill column mb="20px">
            <AddItemIcon onClick={this.onToggleShowForm} label="Add another one contact" />
          </BoxFill>
        )}
      </BoxFill>
    )
  }
}

export default Second
