import styled from 'styled-components'

import { BoxCol, BoxFill, BoxFullFill } from 'styles'

export const Wrap = styled(BoxCol).attrs({})`
  width: 100%;
  min-height: 90vh;
  background: #f5f6f8;
`

export const Header = styled.div`
  width: 100%;
  height: 193px;
  background: #0b1424;
  padding: 0 80px;
`

export const Title = styled.p`
  color: #fff;
  font-weight: bold;
  font-size: 42px;
  line-height: 46px;
  margin: 40px 0 0 0;
`

export const Main = styled(BoxFill).attrs({
  width: '100%',
})`
  padding: 0 80px;
  margin: -50px 0 0 0;
`

export const WrapNoJob = styled(BoxFullFill).attrs({})`
  align-items: center;
  justify-content: center;
  height: 50vh;
  img {
    margin: 0 0 20px 0;
  }
`

export const TitleNoJob = styled.p`
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  color: #292c38;
  margin: 0 0 16px 0;
`
export const SubtitleNoJob = styled.p`
  width: 350px;
  text-align: center;
  font-size: 16px;
  line-height: 20px;
  color: #9caab8;
  span {
    color: #3cb593;
    cursor: pointer;
  }
`
